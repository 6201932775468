import React from "react"
import Intro from "./Intro"
import Section from './Section';
import LogoGrid from './LogoGrid';


const LandingPageComponent = props => {
  return (

    // TODO: in the components used here, use SliderComponent instead of ImageCarousel for consistency -- delete ImageCarousel afterwards

    <div className="lg:grid lg:grid-cols-1 mx-3 lg:mx-12 mb-4">
      <Intro
        intro={props.intro}
      />

      {/*TODO: move dashed-separator to Intro component itself & fix it*/}
      <div className="dashed-separator my-4"></div>

      {props.sections.map((section, i) => (
        <Section
          key={i}
          section={section}
        />
      ))}

      <LogoGrid
        logos={props.logos}
      />


    </div>

  )
}

export default LandingPageComponent
