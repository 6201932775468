import React from 'react';
import ImageCarousel from './ImageCarousel';
import parse from 'html-react-parser';
import { htmlRenderOptions } from "../utils/htmlRenderOptions"


const Intro = ({ intro }) => {
  return (
    <div id="intro-section">
      <p className="font-futurastd_heavy uppercase mb-3 text-4xl">
        {intro.title}
      </p>

      <p className="futurastd_bold mb-3 text-2xl lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
        {intro.subtitle}
      </p>

      <p className="text-base font-futurastd_book">
        {parse(intro.longDescription.html, htmlRenderOptions)}
      </p>

      {intro.sliderImages.length > 0 && (
        <div className="h-full w-full mt-4">
          <ImageCarousel images={intro.sliderImages} />
        </div>
      )}
    </div>
  );
};

export default Intro;
