import React from "react"
import { withTrans } from "../withTrans"
import { graphql } from "gatsby"
import LandingPageComponent from "../components/LandingPageComponent"
import LayoutBareBones from "../components/LayoutBareBones"


export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      landingPage(locales: $locale, where: {id: "clz1t2579rwlz07uune4v3mr4"}) {
        intro {
          title
          subtitle
          longDescription {
            html
          }
          sliderImages(locales: en) {
            url
          }
        }
        section {
          longDescription {
            html
          }
          title
          sectionNumber
          sliderImages(locales: en) {
            height
            width
            url
          }
        }
        logo {
          category
          asset(locales: en) {
            url
          }
        }
        form
      }
    }
  }
`

class Remastered extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intro: this.props.data.gcms.landingPage.intro,
      sections: this.props.data.gcms.landingPage.section,
      formURL: this.props.data.gcms.landingPage.form,
      logos: this.props.data.gcms.landingPage.logo,
    }
  }

  render() {

    const pageName = "remastered"
    const pageSlug = "/" + pageName

    return (
      <LayoutBareBones pathname={pageSlug}>

        <LandingPageComponent
          intro={this.state.intro}
          sections={this.state.sections}
          logos={this.state.logos}
        />

        <div className="iframe-container" id="remastered-google-form">
          <iframe
            title={"Remastered - Google Form"}
            src={this.state.formURL}
            width="100%"
          >
            Form is loading...
          </iframe>
        </div>

      </LayoutBareBones>
    )
  }
}

export default withTrans(Remastered)
