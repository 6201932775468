import React from 'react';
import GenericGrid from './GenericGrid';

const LogoGrid = ({ logos }) => {

  // from a list of logos, create a dict of type {logoCategory:List[logos]}
  const categorizedLogosDict = logos.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {})
  const categorizedLogos = Object.entries(categorizedLogosDict);

  // Separate single-item arrays for special handling
  const singleLogoCategories = categorizedLogos.filter(([_, logos]) => logos.length === 1);
  const multiLogoCategories = categorizedLogos.filter(([_, logos]) => logos.length > 1);

  const singleLogoCategoryWidth = {
    1: 'w-full',
    2: 'w-1/2',
    3: 'w-1/3',
    4: 'w-1/4',
    5: 'w-1/5',
    6: 'w-1/6',
  }

  return (
    <>
      {/* Handle single-logo grid differently on phone vs desktop */}
      <div id="grid-single-container-desktop" className="hidden lg:flex flex-wrap">
        {singleLogoCategories.map(([category, logos], index) => (
          <div
            key={category}
            id={`grid-single-desktop-${index}`}
            className={`flex-none ${singleLogoCategoryWidth[singleLogoCategories.length]} px-2`}
          >
            <GenericGrid title={category} items={logos} />
          </div>
        ))}
        <div className="dashed-separator my-4"></div>
      </div>

      <div id="grid-single-container-mobile" className="block lg:hidden">
        {singleLogoCategories.map(([category, logos], index) => (
          <div key={category} id={`grid-single-mobile-${index}`}>
            <GenericGrid title={category} items={logos} />
            <div className="dashed-separator my-4"></div>
          </div>
        ))}
      </div>

      {/* Handle multiple-logo grids the same on phone & desktop */}
      <div id="grid-multiple-container">
        {multiLogoCategories.map(([category, logos], index) => (
          <div key={category} id={`grid-multiple-${index}`}>
            <GenericGrid title={category} items={logos} />
            <div className="dashed-separator my-4"></div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LogoGrid;
