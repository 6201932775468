import React, { useState, useEffect } from 'react';

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // 5000ms = 5 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <div style={{ textAlign: 'center' }}>
      {images.length > 0 && (
        <div>
          <img
            src={images[currentIndex].url}
            alt={`Slide ${currentIndex}`}
            style={{ width: "100%", height: "auto" }}
          />
          {images.length > 1 && (
            <div style={{ marginTop: "10px" }}>
              {images.map((_, index) => (
                <span
                  key={index}
                  style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    margin: "0 5px",
                    // borderRadius: '50%',
                    backgroundColor: index === currentIndex ? "black" : "lightgray",
                    cursor: "pointer"
                  }}
                  onClick={() => setCurrentIndex(index)}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;