import React from "react"
import ImageCarousel from "./ImageCarousel"
import parse from "html-react-parser"
import { htmlRenderOptions } from "../utils/htmlRenderOptions"


const Section = ({ section }) => {

  const getImageCarouselWidth = (image) => {
    if (image.width <= image.height) {
      return "w-1/2"
    }
    return "w-full"
  }

  return (
    <div id={`section-${section.title}`} className={section.sliderImages.length > 0 ? "section-with-slider-images" : ""}>
      <p className="font-futurastd_heavy uppercase mb-3 text-xl flex justify-center">
        {section.title}
      </p>

      <p className="text-base font-futurastd_book">
        {parse(section.longDescription.html, htmlRenderOptions)}
      </p>

      {section.sliderImages.length > 0 && (
        <div className={`h-full ${getImageCarouselWidth(section.sliderImages[0])} mt-4`}>
          <ImageCarousel images={section.sliderImages} />
        </div>
      )}

      <div className="dashed-separator my-4"></div>
    </div>
  );
};

export default Section;