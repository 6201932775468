import React from "react"
import { withTrans } from "../withTrans"

const GenericGrid = props => {

  const numberColumnsMap = {
    1: 'flex justify-center',
    2: 'grid grid-cols-1 lg:grid-cols-2 gap-4',
    3: 'grid grid-cols-3 lg:grid-cols-3 gap-4',
    4: 'grid grid-cols-2 lg:grid-cols-4 gap-4',
    5: 'grid grid-cols-5 lg:grid-cols-5 gap-4',
  }

  const numberColumns = numberColumnsMap[props.items.length] || numberColumnsMap[1]

  return (
    <div className="mx-3 lg:mx-7">
      <p className="font-futurastd_heavy uppercase mb-3 text-xl flex justify-center">
        {props.title}
      </p>
      <div className={`${numberColumns}`}>
        {props.items.map((item, i) => (
          <div key={i} className="mx-2">
              {item.title ? (
                <p className="font-futurastd_heavy uppercase mb-2">
                  {item.title}
                </p>
              ) : null}
              {item.asset.url ? (
                <img
                  className="h-full w-full max-w-xs object-contain"
                  src={item.asset.url}
                  alt={item.asset.title}
                />
              ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}
export default withTrans(GenericGrid)
