import React from "react"
import { Helmet } from "react-helmet"
import i18next from "i18next"
import { withTrans } from "../withTrans"
import NavigationSlim from "./navbar_slim"

const LayoutBareBones = ({ t, children, pathname }) => {
  return (
    <div>
      <Helmet>
        <html
          dir={i18next.language === `ar` ? `rtl` : `ltr`}
          lang={t(i18next.language)}
        />
      </Helmet>
      <div className="w-full h-full">
        <div className="bg-background-landing-page bg-no-repeat w-full h-full bg-cover overflow-auto fixed top-0">
          <div className="lg:mx-auto lg:max-w-screen-lg lg:m-9 bg-white border-style-landing-page">
            <NavigationSlim t={t} pathname={pathname}> </NavigationSlim>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}



export default withTrans(LayoutBareBones)
